import { MinusOutlined, PlusOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex, MenuProps, Popconfirm, message } from "antd";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetProfileMutation } from "src/app/services/users";
import { changeCollapsed } from "src/app/slices/layoutSlice";
import { logout } from "src/app/slices/usersSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";
import { UserIcon, UserSvg } from "src/assets/svg";
import { CloseBarSvg, OpenBarSvg } from "src/assets/svg/homeIcons";
import { LogOutIcon } from "src/assets/svg/menuIcons";
import CustomButton from "src/components/common/button";
import { isMobile } from "src/static/constants";
import DarkMode from "./components/darkMode";
import SelectLang from "./components/selectLangs";
import "./header.scss";
import {
  JournalActionType,
  changeJournalAction,
} from "src/app/slices/journalSlice";
import { useTranslation } from "react-i18next";
import {
  useGetBranchesMutation,
  useSelectBranchesMutation,
} from "src/app/services/management";
import { IBranchRes } from "src/app/services/management/type";
import { saveBranches } from "src/app/slices/branches";
import { Bank } from "iconsax-react";
import { position } from "src/app/services/users/const";

function Header() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const branches = useTypedSelector((state) => state.branch.branchs);
  const [getProfile, { data, error }] = useGetProfileMutation();
  const navigate = useNavigate();
  const errorRes: any = error;
  const { profile } = useTypedSelector((state) => state.users);
  const userPostion = profile?.position || position;

  const [getBranches, { data: branchesList, isLoading }] =
    useGetBranchesMutation();
  const [selectBranches] = useSelectBranchesMutation();

  useEffect(() => {
    if (branches.length == 0) {
      getBranches("")
        .unwrap()
        .then((val) => {
          let arr: IBranchRes[] = [];
          val.results?.forEach((item) => {
            arr.push({ ...item, key: item.id });
          });
          dispatch(saveBranches(arr));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorRes?.status === 403) {
      navigate("/");
      dispatch(logout());
      message.warning(
        "Faollik muddati tugadi. Iltimos tizimga qaytadan kiring!"
      );
    }
  }, [errorRes]);

  useEffect(() => {
    getProfile();
  }, []);

  const { collapsed, screenMode } = useTypedSelector((state) => state.layout);
  const toggleCollapsed = () => {
    dispatch(changeCollapsed());
  };

  const dropdownItems: MenuProps["items"] = [
    {
      label: (
        <div className="user">
          <Popconfirm
            title="Tizimni tark etishni tasdiqlaysizmi?"
            onConfirm={() => {
              dispatch(logout());
              message.success("You successfully logged out");
              navigate("/");
            }}
            okText="Ha"
            cancelText="Yo'q"
          >
            <div style={{ height: 32 }}>
              <LogOutIcon />
              <p>{t("common.logout")}</p>
            </div>
          </Popconfirm>
        </div>
      ),
      key: "1",
    },
  ];

  if (
    !profile?.parent_company.is_branch &&
    !["cfo", "operator", "accountant"].includes(String(userPostion))
  ) {
    dropdownItems.unshift({
      key: "2",
      expandIcon: false,
      label: (
        <Flex align="center" gap={16} style={{ height: 32 }}>
          <Bank size="18" />
          Profilni almashtirish
        </Flex>
      ),
      children: [
        ...branches.map((item) => ({
          key: String(item.id),
          label: (
            <Button
              type="link"
              style={{ width: "100%", color: "inherit" }}
              onClick={() =>
                selectBranches({ company: item.id }).then(() =>
                  window.location.reload()
                )
              }
            >
              {item.title}
            </Button>
          ),
        })),
      ],
    });
  }

  const dropdownMobileItems: MenuProps["items"] = [
    {
      label: (
        <div className="user">
          <div>
            <UserIcon />
            <p>
              {data?.first_name} {data?.last_name}
            </p>
          </div>
        </div>
      ),
      key: "0",
    },
    ...dropdownItems,
  ];

  //Journal actions section
  const onCash = (val: JournalActionType) => {
    navigate("/dashboard/cash");
    dispatch(changeJournalAction(val));
  };

  return (
    <div className="header">
      <div className="header-left">
        {collapsed ? (
          <span className="header-trigger" onClick={toggleCollapsed}>
            <CloseBarSvg />
          </span>
        ) : (
          <span className="header-trigger" onClick={toggleCollapsed}>
            <OpenBarSvg />
          </span>
        )}
      </div>

      <div className="header-buttons">
        <CustomButton
          onClick={() => onCash("income")}
          className="header-btn-green"
          height={38}
        >
          <PlusOutlined /> {t("common.income")}
        </CustomButton>
        <CustomButton
          onClick={() => onCash("outcome")}
          className="header-btn-red"
          height={38}
        >
          <MinusOutlined /> {t("common.outcome")}
        </CustomButton>
        <Link to="/dashboard/transfer">
          <CustomButton height={38} className="btn-primary">
            <SwapOutlined /> {t("common.transfer")}
          </CustomButton>
        </Link>
      </div>
      <div className="header-right">
        <DarkMode />
        {/* <Currency /> */}
        <SelectLang visible={false} />
        {/* {screenMode === "enter" ? (
          <FullscreenExitOutlined
            onClick={() => dispatch(changeScreenMode("exit"))}
            className="header-right-screen-icon"
          />
        ) : (
          <FullscreenOutlined
            onClick={() => dispatch(changeScreenMode("enter"))}
            className="header-right-screen-icon"
          />
        )} */}
        <Dropdown
          menu={{
            items: isMobile ? dropdownMobileItems : dropdownItems,
            selectable: true,
            defaultSelectedKeys: [String(data?.company.id)],
          }}
          trigger={["click"]}
        >
          <div className="header-profile">
            <UserSvg />
            <div className="header-profile-info">
              <p>
                {data?.first_name} {data?.last_name}
              </p>
              <span className="line-clamp1">{profile?.company.title}</span>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

export default Header;
