export const languageUz = {
  home: {
    hero: {
      title1: "Hisob-kitoblarsiz moliya",
      title2: "= yo'qotilayotgan pullar",
      description:
        "Bugundan pullaringizni to'g'ri boshqarmasangiz, ertaga pul yetishmovchiligi sizni boshqaradi",
      button1: "Tizimga kirish",
      button2: "Kontaktingizni qoldiring",
    },
    explain: {
      title1: "Moliyaviy hisob-kitob",
      title2: "murakkab jarayon",
      card1Title: "Dinamik dashboardlar bilan strategik rejalashtirish",
      card1Text:
        "Batafsil moliyaviy rejalarni tuzing va kompaniyangizning moliyaviy ko'rsatkichlarini haqiqiy vaqtda kuzating, bu esa qaror  qilish  va davomli muvaffaqiyat uchun reja tuzishni osonlashtiradi",
      card2Title: "Sof foydani aniq hisoblang",
      card2Text:
        "Moliyaviy ma'lumotlaringizning to'liq boshqarib, sof fodani aniq hisoblang. Tahlillaringizga moddiy va nomoddiy daromad oqimlarini qo'shib, moliyaviy barqarorligingizni yanada aniqroq qiling, strategik rejalashtiring.",
      card3Title: "Rolga moslashtirilgan kirish",
      card3Text:
        "Har bir xodimning roliga muvofiq ko'rish va funktsiyalilikni moslashtiring, bu jamoa a'zolariga ularning mas'uliyatlariga tegishli ma'lumotlar va vazifalarga e'tibor qaratishga imkon beradi",
      card4Title: "Sun'iy idrok sizni biznesingizni tushinadi",
      card4Text:
        "Sun'iy idrok kuchidan foydalanib, kompaniyangizning moliyaviy holatiga moslashtirilgan shaxsiy moliyaviy maslahat oling. Bizning aqlli tizim moliyaviy holatingizni tushunadi, moliyaviy strategiyangizni optimallashtirish uchun tushunchalar va tavsiyalar taklif etadi.",
      card5Title: "Moliyaviy holatingizni aniq tahlil qiling",
      card5Text:
        "Biznesingizni moliyaviy holatini to'liq kuzating. Bizning keng doiradagi jadvallar va chiziqli grafiklar orqali moliyaviy ko'rsatkichlaringizni chuqur tahlil qiling, batafsil tahlillar bilan qaror qabul qilish jarayonini yaxshilang",
      card6Title: "Moliyaviy kirim-chiqimni tasdiqlash",
      card6Text:
        "Aqcham Telegram Bot orqali jamoa ishlatgan xarajatlar va daromadlar bo'yicha  bildirishnomalar qabul qiladi, bu sizning jamoangiz bo'ylab hisobdorlik va shaffoflikni oshiradi.",
    },
    about: {
      title1: "Dasturning to'g'ridan-to'g'ri",
      title2: "ustunliklari",
      card1Title: "ChatGPT 4 bilan uzviy bog'langan",
      card2Title: "Avtomatik tahlillar",
      card3Title: "Moliya bo'yicha maslahatlar",
      card4Title: "Moliya bo'yicha bepul darsliklar",
      card5Title: "Qulay boshqaruv paneli",
      card6Title: "Dasturni ishlatish bo'yicha videoqo'llanmalar",
    },
    priority: {
      title1: "Aqcham - sun'iy intellekt",
      title2: "yordamida ishlaydigan moliya modeli",
      description:
        "Bu yerda xatoliklar va kamchiliklar bo'lmaydi. Oddiy va zerikarli shablonlar bilan moliya imkoniyatlari cheklanmaydi",
      card1line1: "Barcha ma'lumotlar bir joyda to'planadi",
      card1line2: "Avtomatik hisob-kitoblar",
      card1line3:
        "Avtomatik ravishda yaratilgan grafik va boshqaruv panelidagi tayyor vizual hisobotlar",
      card1line4: "Sun'iy intellekt yordamida optimallashtirish",
      card1line5:
        "O'chib ketgan operatsiyalarni qayta tiklash mumkin. Barcha ma'lumotlar serverda saqlanadi",
      card1line6:
        "Naqd pul bo'shliqlarining oldini olish va oldindan har qanday davr uchun moliyaviy prognozlarni yaratish",
      card2line1: "Interfeysning murakkabligi.",
      card2line2: "Ba'zi hisob-kitoblarning sekinligi.",
      card2line3: "Ijodiy erkinlik yo'q.",
      card2line4: "Ma'lumotlarni kiritish va olishdagi murakkablik",
      card2line5: "Hisob-kitoblar tezligi past",
      card2line6: "Shunga ko'ra, hujjatlar va hisobotlar soni ortadi.",
      card3line1: "Juda ko'p jadvallar",
      card3line2: "Noto'g'ri formulalar tufayli hisob-kitoblardagi xatolar",
      card3line3:
        "Ma'lumotlarni tahlil qilishning murakkabligi va cheklangan hisobot imkoniyatlari",
      card3line4: "Ma'lumotlarni qo'lda kiritish",
      card3line5: "Xavfsizlik darajasi past",
      card3line6: "Excel'da pul oqimi rejasini tuzib bo‘lmaydi",
    },
    team: {
      title: "Bizning jamoa",
      card1Title: "YILLIK TAJRIBA",
      card2Title: "MOLIYA MODELLARI",
      card3Title: "MOLIYACHI DIREKTORLAR",
      card4Title: "LOYIHA",
    },
    partners: {
      title1: "Bizga ishongan",
      title2: "hamkorlarimiz",
    },
    contact: {
      title1: "Istalgan vaqtda",
      title2: "biz bilan bog'laning",
      formTitle: "Raqamingizni qoldiring, biz siz bilan bog'lanamiz",
      button: "Yuborish",
    },
    footer: {
      description:
        "Moliyani boshqarish uchun onlayn dastur. Bu dastur orqali pulni, daromadni, foydani boshqarasiz!",
      linkTitle: "Havolalar",
      blogTitle: "Blog",
      blogLine1: "Foydalanish shartlari",
      blogLine2: "Maxfiylik siyosati",
      adressTitle: "BIZNING MANZIL",
      adressLine1:
        "Тошкент шахри, Шота Руставели кўчаси, 1-уй. МФактор биносида",
      contactButton: "Bizga aloqaga chiqing",
    },
    menu: {
      menu1: "Dastur haqida",
      menu2: "Imkoniyatlar",
      menu3: "Hamkorlar",
      menu4: "Narxlar",
      menu5: "Bog'lanish",
    },
  },
  auth: {
    signUp: {
      title1: "Aqchamga ",
      title2: "xush kelibsiz",
      description:
        "Biznesda aniq raqamlarni bilmasangiz, foyda va zarar, kirim va chiqim hisobotlari bilan tanish bo'lmasangiz, aniq yutqazasiz",
      formTitle: "AQCHAM ga xush kelibsiz",
      firstNameLabel: "Ism",
      firstNamePlaceholder: "Ismingiz",
      lastNameLabel: "Familya",
      lastNamePlaceholder: "Familyangiz",
      emailLabel: "Email",
      emailPlaceholder: "Email pochtangiz",
      passwordLabel: "Parol",
      passwordPlaceholder: "Parolingiz",
      confirmPasswordLabel: "Parolni tasdiqlash",
      confirmPasswordPlaceholder: "Parolni tasdiqlash",
      bottomText: "Agar sizda “AQCHAM“ hisobi bo'lsa?",
      button: "Ro'yxatdan o'tish",
      signInLink: "Tizimga kirish",
    },
    signIn: {
      formTitle: "Xush kelibsiz!",
      emailLabel: "Email",
      emailPlaceholder: "Email pochtangiz",
      passwordLabel: "Parol",
      passwordPlaceholder: "Parolni kiriting",
      forgetPassword: "Parolni unutdingizmi?",
      button: "Kirish",
      bottomText: "Agar sizda “AQCHAM“ hisobi yo'q bo'lsa?",
      signInLink: "Ro'yxatdan o'tish",
      commentLink: "So'rov qoldirish",
    },
    comment: {
      businessLabel: "Biznesingiz haqida",
      send: "Yuborish",
      phone: "Telefon raqam",
    },
  },
  sidebar: {
    statistics: {
      title: "Kontrol panel",
      menu1: "Cashflow",
      menu2: "Foyda va zarar",
      // menu3: "Yo'nalishlar",
      // menu4: "Pul oqimi ulushlari",
      // menu5: "Manba ulushlari",
      // menu6: "Daromad va Sof foyda",
      // menu7: "Marginallik",
      // menu8: "Yalpi foyda",
      // menu9: "Operativ foyda",
      // menu10: "Sof foyda",
    },
    check: {
      title: "Tasdiqlash",
      menu1: "Tahrirlangan",
    },
    cash: {
      title: "Kassa",
      menu1: "Kassa jurnali",
      menu2: "Kassa chiqarish",
      menu3: "O'tkazma",
    },
    cashflow: {
      title: "Pul oqimi",
      selectTitle: `Pul oqimini tanlang`,
    },
    pnl: {
      title: "Foyda va Zarar",
      menu1: "Foyda va Zarar",
      menu2: "Majburiyat",
      menu3: "AKT",
      statistcCashflow: `Cashflow statistika ma'lumotlari`,
      statistcPnl: `Foyda va zarar statistika ma'lumotlari`,
      debitor: `Debitor & Kreditor`,
    },
    planned: {
      title: "Rejalashtirish",
      menu1: "Reja kirim & chiqim",
      menu2: "Byudget pul oqimi",
    },
    info: {
      title: "Ma'lumotlar",
      menu1: "Valyutalar",
      menu2: "Manbalar",
      menu3: "Hisob raqamlar",
      menu4: "Yo'nalishlar",
      menu5: "Xodimlar",
      menu6: "Kontragentlar",
    },
    kompany: {
      title: "Kompaniya",
    },
    branchs: {
      title: "Filiallar",
    },
  },
  common: {
    all: `Jami`,
    uzs: `so'm`,
    usd: `dollar`,
    rub: `rubl`,
    eur: `yevro`,
    gbp: `funt`,
    name: `Nomi`,
    view: `Ko'rish`,
    enterName: `Nomini kiriting`,
    income: `Kirim`,
    outcome: `Chiqim`,
    currency: `Valyuta`,
    currencyRate: `Valyuta kursi`,
    currencyRateForDollar: `Valyuta kursi ($1 uchun)*`,
    transfer: `O'tkazma`,
    today: `Bugun`,
    thisMonth: `Bu oy`,
    lastMonth: `O'tgan oy`,
    difference: `Farq`,
    date: `Sana`,
    intervalDate: `Oraliq sana`,
    actions: `Amallar`,
    plan: `Reja`,
    home: `Bosh sahifa`,
    dashboard: `Dashboard`,
    fact: `Fakt`,
    percent: `Foiz`,
    profit: `Daromad`,
    comment: `Izoh`,
    position: `Lavozim`,
    enterComment: `Izohni kiriting`,
    total: `Umumiy`,
    barchasi: `Barchasi`,
    directions: `Yo'nalishlar`,
    direction: `Yo'nalish`,
    search: `Qidirish`,
    clear: `Tozalash`,
    currentMoneyFlow: `Joriy pul oqimi`,
    financialMoneyFlow: `Moliyaviy pul oqimi`,
    invistationMoneyFlow: `Investitsion pul oqimi`,
    naklodnoyOutcome: `Nakladnoy xarajat`,
    administrativeOutcome: `Administrativ xarajat`,
    commersialOutcome: `Tijoriy xarajat`,
    expense: `Xarajat`,
    expenseType: `Xarajat turi`,
    subSources: `Sub manbalar`,
    enterSubSources: `Sub manbalar`,
    taxes: `Soliqlar`,
    users: `Xodimlar`,
    user: `Xodim`,
    addUuser: `Xodim qo'shish`,
    month: `Oy`,
    monthly: `Oylik`,
    whichMonth: `Qaysi oy`,
    whichMonthInfo: `Xodim qaysi oy uchun ish xaqi oldi?`,
    value: `Tannarx`,
    enterValue: `Tannarxni kiriting`,
    netProfit: `Sof foyda`,
    grosProfit: `Yalpi foyda`,
    operativProfit: `Operative foyda`,
    morjanalProfit: `Morjanal foyda`,
    totalBalance: `Umumiy qoldiq`,
    paymentDifference: `To'lov farqi`,
    accounts: `Akkountlar`,
    sources: `Manbalar`,
    source: `manba`,
    Usource: `Manba`,
    sourceType: `Manba turi`,
    addsource: `Manba qo'shish`,
    entersource: `Manba nomini kiriting`,
    editsource: `manabsini tahrirlash`,
    selectAccount: `Hisob raqamni tanlang`,
    account: `Hisob raqam`,
    contingents: `Kontragentlar`,
    contingent: `Kontragent`,
    addcontingent: `Yangi kontragent qo'shish`,
    typeContingent: `Kontragent turi`,
    selectContingent: `Kontragentni tanlang`,
    addContingent: `Kontragent qo'shish`,
    nameContingent: `Kontragentni nomi`,
    enterContingent: `Kontragentni nomini kiriting`,
    selectYear: `Yilni tanlang`,
    selectDate: `Sanani tanlang`,
    selectMonth: `Oyni tanlang`,
    selectSource: `Manbani tanlang`,
    selectCurrency: `Valyutani tanlang`,
    enterCurrencyRate: `Valyuta kursini kiriting`,
    selectUser: `Xodimni tanlang`,
    more: `Batafsil`,
    filter: `Filter`,
    check: `Tekshirish`,
    save: `Saqlash`,
    cancel: `Bekoq qilish`,
    downloadExcel: `Excel yuklash`,
    amount: `Miqdor`,
    enterAmount: `Miqdorni kiriting`,
    add: `Qo'shish`,
    _add: `qo'shish`,
    enterPrice: `Narxni kriting`,
    remain: `Qoldiq`,
    entitlement: `haqdorlik`,
    deposit: `omonat`,
    logout: `Tizimdan chiqish`,
    doTransfer: `O'tkazish`,
    editInfo: ` ma'lumotlarini tahrilash`,
    priceSum: "Narx (So'm)",
    successMessage: "Muvaffaqiyatli qo'shildi",
    allBranchsStat: "Barcha statistikalar",
  },
  months: {
    jan: `Yanvar`,
    feb: `Fevral`,
    march: `Mart`,
    apr: `Aprel`,
    may: `May`,
    jun: `Iyun`,
    jul: `Iyul`,
    aug: `Avgust`,
    sep: `Sentabr`,
    oct: `Oktabr`,
    nov: `Noyabr`,
    dec: `Dekabr`,
  },
  dashboard: {
    title: `Boshqaruv panel`,
    incomeFact: `Tushum reja & fakt`,
    outcomeFact: `Chiqim reja & fakt`,
    restFact: `Operativ qoldiq`,
    daromadFact: `Daromad reja & fakt`,
    xarajatFact: `Xarajat reja & fakt`,
    foydaFact: `Foyda reja & fakt`,
    paymentYear: `To'lov kalendari yil`,
    paymentCalendar: `To'lov kalendari`,
  },
  subControlPanel: {
    title: "Boshqaruv hisobotlar",
    balance: `Pul qoldig'i`,
    expenses: `Manba ulushlari`,
    income: `Kirim va chiqim`,
    revenue: `Pul oqimi ulushlari`,
    profit_soft: `Daromad va Sof foyda`,
    overhead: `Marginallik`,
    sourceMontly: `Manba/Oylar`,
  },
  subCheck: {
    uncorfirmedAcc: `Tasdiqlanmagan hisoblar`,
    uncorfirmed: `Tasdiqlanmagan`,
    unAnswered: `Javob berilmagan`,
  },
  subCash: {
    historyCash: `Kassa tarixi`,
    historyTransfer: `O'tkazmalar tarixi`,
    exitCash: `Kassa chiqdi`,
    from: `Akkauntdan`,
    to: `Akkauntga`,
    size: `O'tkazilgan miqdor`,
    transferAmount: `O'tkazma summasi`,
    enterTransferAmount: `O'tkazma summasi`,
    transferDate: `O'tkazilgan sana`,
    toTransferDate: `O'tkazish sanasi`,
    selectToAcc: `Kirivchi akkountni tanlang`,
    selectFromAcc: `Chiquvchi akkountni tanlang`,
    hisoboti: `hisoboti`,
    exactAccount: `Mos hisob raqam`,
    exactAccountBalance: `Mos hisob raqamdagi qoldiq `,
    enterExactAccountBalance: `Mos hisob raqamdagi qoldiqni kiriting !`,
    system: `Sistema`,
    difesit: `Kamomat`,
    bankBalance: `Bankdagi qoldiq`,
    systemBalance: `Sistemadagi qoldiq`,
    startMonthBalance: `Oy boshiga qoldiq`,
    currentBalance: `Joriy pul oqimi qoldig'i`,
    financialBalance: `Moliyaviy pul oqimi qoldig'i`,
    invsetialBalance: `Invistitsion pul oqimi qoldig'i`,
    changeAccount: `Hisob raqam almashuvi`,
    total_change: `Umumiy o'zgarma`,
    remains_last: `Oy oxiriga qoldiq`,
    profit: `Daromad`,
    enterProfit: `Daromadni kririting`,
    profitPercent: `Daromaddagi %`,
    marginProfit: `Marjanal daromad`,
    marginPercent: `Marjanalnist %`,
    overheadOutcome: `Nakladnoy xarajatlar`,
    grossAll: `Yalpi foyda (+)/Zarar(-)`,
    grossRentabl: `Yalpi foyda rentabilligi %`,
    operativeAll: `Operativ foyda (+)/Zarar(-)`,
    operativRentabl: `Operativ foyda rentabilligi %`,
    deprecation: `Amortizatsiya AB`,
    sof: `Sof foyda (+)/Zarar(-)`,
    sofRentabl: `Sof foyda rentabilligi %`,
    remansTitle: `Oyni oxiriga so'mda`,
    mainTools: `Asosiy vositalar`,
    partProfit: `Taqsimlanmagan foyda`,
    warehause: `Ombor`,
    material: `Xom ashyo`,
    halfProduct: `Yarim tayyor mahsulot`,
    product: `Tayyor mahsulot`,
    debit: `Debitorka`,
    credit: `Kreditorka`,
    duties: `Majburiyatlar`,
    suplier: `Mol yetkazib beruvchilardan`,
    _suplier: `Mol yetkazib beruvchi`,
    customers: `Xaridorlardan`,
    customer: `Xaridor`,
    selectCustomer: `Xaridorni tanlang`,
    emplyee: `Xodimlardan`,
    rent: `Soliqdan`,
    moneys: `Pullar`,
    balans: `Balans`,
    personalCapital: `Shaxsiy kapital`,
    active: "Aktiv",
    passive: "Passiv",
    workingCapital: `Aylanma mablag'lar`,
    budgetTitle: `Byudjet pul oqimi`,
    budgetPnlTitle: `Byudjet foyda zarar`,
    budget_pnl_list: `Reja daromad & xarajat`,
    budget_pnl_by_month: `Byudjet foyda & zarar`,
    selectAccount: `Iltimos akkauntlardan birini tanlang!`,
  },
  subHelper: {
    storage: "Ombor",
    storageAddTitle: "Ombor ma'lumotlarini qo'shish",
    storageType: "Maxsulot turi",
    selectStorageType: "Maxsulot turini tanlang",
    storageRowMaterials: "Xom ashyo",
    storageSemiFinished: "Yarim tayyor maxsulot",
    storageFinished: "Tayyor maxsulot",
    salary: `Ish haqi`,
    debitCredit: `Debit-kredit`,
    purchase: `Xaridlar`,
    mainToll: `Asosiy vositalar`,
    title: `Yordamchi hisobotlar`,
    editTitle: `Ish haqini tahrirlash`,
    addTitle: `Ish haqini qo'shish`,
    editDutyTitle: `Majburiyatni tahrirlash`,
    addDutyTitle: `Majburiyatni qo'shish`,
    aktNumber: `Akt raqami`,
    enterAktNumber: `Akt raqamini kiriting`,
    aktDate: `Akt sanasi`,
    serviceType: `Xizmat turi`,
    editAktTitle: `Majburiyatni tahrirlash`,
    addAktTitle: `Majburiyatni qo'shish`,
    editPurchaseTitle: `Xaridni tahrirlash`,
    addPurchaseTitle: `Xaridni qo'shish`,
    purchaseDate: `Xarid qilish sanasi`,
    attritionMonthly: `Eskirish (oylar kesimida)`,
    monthly_rate: `Bir oylik amartizatsiya`,
    total_months: `Necha oy foydalaniladi`,
    current_balance: `Qoldiq summasi (So'm)`,
    initial_balance: `Boshlang'ich narxi (So'm)`,
    editMainTitle: `Asosiy vositani tahrirlash`,
    addMainTitle: `Asosiy vositani qo'shish`,
    editInformation: `ma'lumotlarini tahrirlash`,
    bookWithCustomers: `Xaridorlar bilan hisob kitoblar`,
    madeService: `Xizmat ko'rsatildi`,
    payedCustomer: `Xaridor to'ladi`,
    bookWithDillers: `Yetkazib beruvchilar bilan hisob-kitoblar`,
    buyed: `Xarid qilindi`,
    payed: `To'landi`,
    bookWithEmployes: `Xodimlar bilan hisob-kitoblar`,
    calculated: `Hisoblandi`,
  },
  subInfo: {
    initial_money: `Boshlang'ich qoldiq`,
    enterInitial_money: `Boshlang'ich qoldiqni kiriting`,
    addAccount: `Hisob raqam qo'shish`,
    editAccount: `hisob raqamini tahrirlash`,
    nameAccount: `Hisob raqam nomi`,
    enterAccount: `Hisob raqam nomini kiriting`,
    balanceAccount: `Hisob raqam balansi`,
    enterBalanceAccount: `Hisob raqam balansini kiriting`,
    addDirection: `Yo'nalish qo'shish`,
    editDirection: `Yo'nalish ma'lumotlarini tahrirlash`,
    nameDirection: `Yo'nalish nomi`,
    enterDirection: `Yo'nalish nomini kiriting`,
    phoneNumber: `Telefon raqam`,
    enterCtrPhone: `Kontagent telefon raqamini kiriting`,
  },
  company: {
    alert: `Iltimos tizimdan foydalanish uchun avval o'z kompaniyangizni kiriting`,
    addCompany: `Kompaniya qo'shish`,
    nameCompany: `Kompaniya nomi`,
    enterCompany: `Kompaniya nomini kiriting`,
  },
  branches: {
    addBranch: `Filial qo'shish`,
    nameBranch: `Filial nomi`,
    enterBranch: `Filial nomini kiriting`,
  },
};
