import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { IBaseRes } from "src/app/services/type";

export const clearMask = (val: string) => {
  return val
    .replaceAll(" ", "")
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll("-", "");
};

export const makePhoneMask = (val: string) => {
  return `(${val[0] + val[1]})-${val[2] + val[3] + val[4]}-${val[5] + val[6]}-${
    val[7] + val[8]
  }`;
};

export const makeDateFormat = (date: any) => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  let m = mm < 10 ? "0" + mm : mm;
  let d = dd < 10 ? "0" + dd : dd;

  const formattedDate = yyyy + "-" + m + "-" + d;
  return formattedDate;
};

export const makeMonthFormat = (date: any) => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let m = mm < 10 ? "0" + mm : mm;

  const formattedDate = yyyy + "-" + m;
  return formattedDate;
};

export const handlePreviewFile = async (file: UploadFile) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

export const handleMakeUrl = (
  searchParams: URLSearchParams,
  additionalParams?: any
) => {
  let url = ``;
  let size = Number(searchParams.get("size") || 10);
  let page = Number(searchParams.get("page") || 1);

  for (let key of Array.from(searchParams.keys())) {
    if (key === "size") url = url + `&limit=${size}`;
    else if (key === "page") url = url + `&offset=${(page - 1) * size}`;
    else {
      let value = searchParams.get(key);
      url = url + `&${key}=${value}`;
    }
  }

  if (additionalParams) {
    if (url === "") url = "limit=10&offset=0";
    for (let key in additionalParams) {
      url = url + `&${key}=${additionalParams[key]}`;
    }
  }
  return url === "" ? "limit=10&offset=0" : url;
};

export const handleMakeDashboardUrl = (
  searchParams: URLSearchParams,
  key2?: string
) => {
  let url = ``;

  for (let key of Array.from(searchParams.keys())) {
    let value = searchParams.get(key);
    if (value === "yesterday" || value === "today") value = "day";
    let param_key = key2 && key === "type" ? key2 : key;
    if (key !== "type") {
      url = url + `&${param_key}=${value}`;
    }
  }

  return url;
};

export const handleMakeUrlNoPage = (searchParams: URLSearchParams) => {
  let url = ``;

  for (let key of Array.from(searchParams.keys())) {
    let value = searchParams.get(key);
    url = url + `&${key}=${value}`;
  }

  return url;
};

export const handleMakeStatUrl = (
  searchParams: URLSearchParams,
  keys?: string[]
) => {
  let url = ``;
  for (let key of Array.from(searchParams.keys())) {
    if (keys?.includes(key)) {
      let value = searchParams.get(key);
      let key_new = key.includes("date") ? "date" : key;
      url = url + `&${key_new}=${value}`;
    }
  }

  return url;
};

export const redirectToNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const prettierNumber = (val: number | undefined, seperator?: string) => {
  if (val) {
    let val_arr = val.toString().split(".");
    let number = val_arr[0].split("").reverse();
    let number_rest = val_arr[1] ? "." + val_arr[1] : "";
    let prettier_number_arr: string[] = [];

    number.forEach((item, index) => {
      if (index > 0 && index % 3 === 0)
        prettier_number_arr.push(seperator || ",");
      prettier_number_arr.push(item);
    });

    return prettier_number_arr.reverse().join("") + number_rest;
  } else return "";
};

export function cyrillicToLatin(input: string): string {
  const mapping: { [key: string]: string } = {
    А: "A",
    Б: "B",
    В: "V",
    Г: "G",
    Д: "D",
    Е: "E",
    Ё: "Yo",
    Ж: "J",
    З: "Z",
    И: "I",
    Й: "Y",
    К: "K",
    Л: "L",
    М: "M",
    Н: "N",
    О: "O",
    П: "P",
    Қ: "Q",
    Р: "R",
    С: "S",
    Т: "T",
    У: "U",
    Ў: "O'",
    Ғ: "G'",
    Ф: "F",
    Х: "Kh",
    Ҳ: "H",
    Ц: "Ts",
    Ч: "Ch",
    Ш: "Sh",
    Щ: "Shch",
    Ъ: '"',
    Ы: "Y",
    Ь: "'",
    Э: "E",
    Ю: "Yu",
    Я: "Ya",
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "yo",
    ж: "j",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    қ: "q",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ў: "o'",
    ғ: "g'",
    ф: "f",
    х: "x",
    ҳ: "h",
    ц: "ts",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ъ: '"',
    ы: "y",
    ь: "'",
    э: "e",
    ю: "yu",
    я: "ya",
  };

  let result = "";
  for (const char of input) {
    result += mapping[char] || char; // Use original char if no mapping exists
  }
  return result;
}

export const findMonth = (month: string) => {
  if (month === "01") return "jan";
  else if (month === "02") return "feb";
  else if (month === "03") return "mar";
  else if (month === "04") return "apr";
  else if (month === "05") return "may";
  else if (month === "06") return "june";
  else if (month === "07") return "july";
  else if (month === "08") return "aug";
  else if (month === "09") return "sep";
  else if (month === "10") return "oct";
  else if (month === "11") return "nov";
  else if (month === "12") return "dec";
  else return "";
};

export const findMonthByIndex = (month: number) => {
  if (month === 1) return "jan";
  else if (month === 2) return "feb";
  else if (month === 3) return "mar";
  else if (month === 4) return "apr";
  else if (month === 5) return "may";
  else if (month === 6) return "june";
  else if (month === 7) return "july";
  else if (month === 8) return "aug";
  else if (month === 9) return "sep";
  else if (month === 10) return "oct";
  else if (month === 11) return "nov";
  else if (month === 12) return "dec";
  else return "jan";
};

export const addKeyForData = <T extends IBaseRes<{}>>(arr: T[]) => {
  return arr?.map((item) => {
    return { key: item?.id, ...item };
  });
};

export const handleMakeMonthObj = (title: string, key = "") => {
  return {
    key,
    title,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    june: 0,
    july: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
  };
};
