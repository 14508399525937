import { IJournalChange, IJournalResData } from "src/app/services/finance/type";
import { DeleteIcon, EditedIcon } from "src/assets/svg";
import CustomButton from "src/components/common/button";
import { Popconfirm, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { journalColumns } from "src/pages/sub_cash/journal/constants";
import { useTypedSelector } from "src/app/store";

interface Props extends IJournalChange {
  onConfirm: (val: number) => void;
  onDelete: (val: number) => void;
}

function EditedCard({
  onConfirm,
  onDelete,
  id,
  date,
  income,
  outcome,
  currency,
  account,
  source,
  comment,
  user,
  cashflow,
}: Props) {
  const { t } = useTranslation();

  const { journalChanges } = useTypedSelector((state) => state.journal);
  console.log(journalChanges);

  const columns: ColumnsType<IJournalResData> = [
    {
      title: t("common.date"),
      dataIndex: "date",
      key: "date",
      fixed: "left",
      width: 110,
    },
    ...journalColumns(t).splice(0, journalColumns(t).length - 1),
  ];

  return (
    <div className="edited-card">
      <Popconfirm
        title="Delete the confirmation"
        description={`Are you sure to confirm delete the confirmation?"`}
        onConfirm={() => onDelete?.(id)}
        okText="Delete"
        cancelText="Cancel"
      >
        <div className="edited-card-delete">
          <DeleteIcon />
        </div>
      </Popconfirm>

      {/* <div className="edited-card-table">
        <div>{cashflow?.date}</div>
        <div>{+cashflow?.income ? +cashflow?.income : ""}</div>
        <div>{+cashflow?.outcome ? +cashflow?.outcome : ""}</div>
        <div>{cashflow?.currency}</div>
        <div>{cashflow?.account?.title}</div>
        <div>{cashflow?.source?.title}</div>
        <div>{cashflow?.user?.title}</div>
        <Popover trigger="click" content={cashflow?.comment}>
          <div className="line-clamp1">{cashflow?.comment}</div>
        </Popover>
      </div>
      <div className="edited-card-icon">
        <EditedIcon />
      </div>
      <div className="edited-card-table">
        <div className={cashflow?.date !== date ? "changed" : ""}>{date}</div>
        <div className={cashflow?.income !== income ? "changed" : ""}>
          {+income ? +income : ""}
        </div>
        <div className={cashflow?.outcome !== outcome ? "changed" : ""}>
          {+outcome ? +outcome : ""}
        </div>
        <div className={cashflow?.currency !== currency ? "changed" : ""}>
          {currency}
        </div>
        <div
          className={
            cashflow?.account?.title !== account?.title ? "changed" : ""
          }
        >
          {account?.title}
        </div>
        <div
          className={cashflow?.source?.title !== source?.title ? "changed" : ""}
        >
          {source?.title}
        </div>
        <div className={cashflow?.user?.title !== user?.title ? "changed" : ""}>
          {user?.title}
        </div>
        <Popover trigger="click" content={comment}>
          <div
            className={
              cashflow?.comment !== comment
                ? "changed line-clamp1"
                : "line-clamp1"
            }
          >
            {comment}
          </div>
        </Popover>
      </div>

      <br />
      <br />
      <br />
      <br /> */}

      <Table
        columns={columns}
        dataSource={journalChanges}
        scroll={{ x: 1200 }}
        bordered={true}
        className="journal-table"
        pagination={false}
      />
      <div className="edited-card-icon">
        <EditedIcon />
      </div>
      <Table
        columns={columns}
        dataSource={journalChanges}
        scroll={{ x: 1200 }}
        bordered={true}
        className="journal-table"
        pagination={false}
      />

      <Popconfirm
        onConfirm={() => onConfirm(id)}
        title="Ma'lumot o'zgarishini tasdiqlaysizmi?"
        okText="Ha"
        cancelText="Yo'q"
      >
        <CustomButton type="text" className="edited-card-btn ">
          {t("sidebar.check.title")}
        </CustomButton>
      </Popconfirm>
    </div>
  );
}

export default EditedCard;
