import { IContingent } from "src/app/services/management/type";
import { TFunctionType } from "src/static/constants";

interface Props {
  title: string;
  title2: string;
  t: TFunctionType;
}

export interface IDebtCreditTable {
  customer: IDebtCreditTableObj[];
  employee: IDebtCreditTableObj[];
  rent: IDebtCreditTableObj[];
  supplier: IDebtCreditTableObj[];
}

export interface IDebtCreditTableObj {
  contingent: IContingent;
  // title: string;
  jan_gap?: number | string;
  jan_debt?: number | string;
  jan_credit?: number | string;
  feb_gap?: number | string;
  feb_debt?: number | string;
  feb_credit?: number | string;
  mar_gap?: number | string;
  mar_debt?: number | string;
  mar_credit?: number | string;
  apr_gap?: number | string;
  apr_debt?: number | string;
  apr_credit?: number | string;
  may_gap?: number | string;
  may_debt?: number | string;
  may_credit?: number | string;
  june_gap?: number | string;
  june_debt?: number | string;
  june_credit?: number | string;
  july_gap?: number | string;
  july_debt?: number | string;
  july_credit?: number | string;
  aug_gap?: number | string;
  aug_debt?: number | string;
  aug_credit?: number | string;
  sep_gap?: number | string;
  sep_debt?: number | string;
  sep_credit?: number | string;
  oct_gap?: number | string;
  oct_debt?: number | string;
  oct_credit?: number | string;
  nov_gap?: number | string;
  nov_debt?: number | string;
  nov_credit?: number | string;
  dec_gap?: number | string;
  dec_debt?: number | string;
  dec_credit?: number | string;
}

export const defCreditTableObj = {
  jan_gap: 0,
  jan_debt: 0,
  jan_credit: 0,
  feb_gap: 0,
  feb_debt: 0,
  feb_credit: 0,
  mar_gap: 0,
  mar_debt: 0,
  mar_credit: 0,
  apr_gap: 0,
  apr_debt: 0,
  apr_credit: 0,
  may_gap: 0,
  may_debt: 0,
  may_credit: 0,
  june_gap: 0,
  june_debt: 0,
  june_credit: 0,
  july_gap: 0,
  july_debt: 0,
  july_credit: 0,
  aug_gap: 0,
  aug_debt: 0,
  aug_credit: 0,
  sep_gap: 0,
  sep_debt: 0,
  sep_credit: 0,
  oct_gap: 0,
  oct_debt: 0,
  oct_credit: 0,
  nov_gap: 0,
  nov_debt: 0,
  nov_credit: 0,
  dec_gap: 0,
  dec_debt: 0,
  dec_credit: 0,
};

export const makeDebtCreditColumns = ({ title, title2, t }: Props) => {
  return [
    {
      title: `1-${t("months.jan").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "jan_gap",
          key: "jan_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.jan"),
      children: [
        {
          title,
          dataIndex: "jan_debt",
          key: "jan_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "jan_credit",
          key: "jan_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.feb").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "feb_gap",
          key: "feb_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.feb"),
      children: [
        {
          title,
          dataIndex: "feb_debt",
          key: "feb_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "feb_credit",
          key: "feb_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.march").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "mar_gap",
          key: "mar_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.march"),
      children: [
        {
          title,
          dataIndex: "mar_debt",
          key: "mar_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "mar_credit",
          key: "mar_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.apr").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "apr_gap",
          key: "apr_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.apr"),
      children: [
        {
          title,
          dataIndex: "apr_debt",
          key: "apr_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "apr_credit",
          key: "apr_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.may").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "may_gap",
          key: "may_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.may"),
      children: [
        {
          title,
          dataIndex: "may_debt",
          key: "may_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "may_credit",
          key: "may_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.jun")}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "june_gap",
          key: "june_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.jun"),
      children: [
        {
          title,
          dataIndex: "june_debt",
          key: "june_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "june_credit",
          key: "june_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.jul")}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "july_gap",
          key: "july_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.jul"),
      children: [
        {
          title,
          dataIndex: "july_debt",
          key: "july_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "july_credit",
          key: "july_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.aug").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "aug_gap",
          key: "aug_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.aug"),
      children: [
        {
          title,
          dataIndex: "aug_debt",
          key: "aug_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "aug_credit",
          key: "aug_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.sep").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "sep_gap",
          key: "sep_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.sep"),
      children: [
        {
          title,
          dataIndex: "sep_debt",
          key: "sep_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "sep_credit",
          key: "sep_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.oct").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "oct_gap",
          key: "oct_gap",
          width: 100,
        },
      ],
    },
    {
      title: "months.oct",
      children: [
        {
          title,
          dataIndex: "oct_debt",
          key: "oct_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "oct_credit",
          key: "oct_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.nov").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "nov_gap",
          key: "nov_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.nov"),
      children: [
        {
          title,
          dataIndex: "nov_debt",
          key: "nov_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "nov_credit",
          key: "nov_credit",
          width: 100,
        },
      ],
    },
    {
      title: `1-${t("months.dec").slice(0, 3)}`,
      children: [
        {
          title: t("common.remain"),
          dataIndex: "dec_gap",
          key: "dec_gap",
          width: 100,
        },
      ],
    },
    {
      title: t("months.dec"),
      children: [
        {
          title,
          dataIndex: "dec_debt",
          key: "dec_debt",
          width: 100,
        },
        {
          title: title2,
          dataIndex: "dec_credit",
          key: "dec_credit",
          width: 100,
        },
      ],
    },
  ];
};
